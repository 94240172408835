import {BrowserRouter, Route, Routes} from "react-router-dom";
import Header from "./Components/Header.jsx";
import Home from "./pages/home.jsx";
import NotFound from "./pages/notFound.jsx";
import Footer from "./Components/Footer.jsx";
import QuiSuiJe from "./pages/quiSuiJe.jsx";
import Competences from "./pages/competences.jsx";
import ContactForm from "./pages/contactForm.jsx";
import Projets from "./pages/projets.jsx";

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Header/>
                <Routes>
                    <Route exact path="/" element={<Home/>}/>
                    <Route exact path="/quiSuiJe" element={<QuiSuiJe/>}/>
                    <Route exact path="/competences" element={<Competences/>}/>
                    <Route exact path="/projets" element={<Projets/>}/>
                    <Route exact path="/contact" element={<ContactForm/>}/>
                    <Route path="*" element={<NotFound/>}/>
                </Routes>
                <Footer/>
            </BrowserRouter>
        </div>
    );
}

export default App;
